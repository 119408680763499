import React, { useEffect, useState } from "react";
import NavBar from "../../components/navigation-header";
import Footer from "../../components/footer";
import "../../styling/pages.css";
import RigthtPage from "../../components/rigtht-page";
import Form from "./form";
import newsJson from "../../data/news.json";

export default function Template({ pageContext }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { readMoreData } = pageContext;

  // console.log(readMoreData);
  const [prevId, setPrevId] = useState(null);
  const [nextId, setNextId] = useState(null);

  const [prevData, setPrevData] = useState(null);
  const [nextData, setNextData] = useState(null);

  const getJsonData = (id) => {
    const foundData = newsJson
      ?.flatMap((month) => month.data)
      .find((data) => data.id === id);

    return foundData;
  };

  useEffect(() => {
    const targetId = readMoreData?.id;
    const flattenedData = newsJson.flatMap((month) => month.data);
    const currentIndex = flattenedData.findIndex(
      (data) => data.id === targetId
    );

    if (currentIndex !== -1) {
      const previousIdData =
        currentIndex > 0 ? flattenedData[currentIndex - 1].id : null;
      const nextIdData =
        currentIndex < flattenedData.length - 1
          ? flattenedData[currentIndex + 1].id
          : null;

      console.log("Current ID:", targetId);
      console.log("Previous ID:", previousIdData);
      console.log("Next ID:", nextIdData);

      setPrevId(previousIdData);
      setNextId(nextIdData);

      if (previousIdData) {
        setPrevData(getJsonData(previousIdData));
      }
      if (nextIdData) {
        setNextData(getJsonData(nextIdData));
      }
    } else {
      console.log("ID not found");
    }
  }, []);

  const paras = readMoreData?.readmorepara;

  const makePara = (para) => {
    const value = para?.split("\\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
        <br />
      </React.Fragment>
    ));

    return value;
  };

  const handleScrollToForm = () => {
    const formElement = document.getElementById("form-section");
    if (formElement) {
      formElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div style={{ backgroundColor: "#F5F5F5" }}>
      <NavBar />

      <div className="pc">
        <center>
          <div className="store">
            <table
              style={{
                width: "90%",
                textAlign: "start",
                marginTop: "150px",
                marginBottom: "30px",
              }}
            >
              <tbody>
                <tr>
                  <td style={{ width: "70%", verticalAlign: "top" }}>
                    <div style={{ backgroundColor: "#fff" }}>
                      <div style={{ padding: "3em", paddingBottom: "0em" }}>
                        <img
                          className="news-image"
                          src={readMoreData?.aimage}
                          alt="read-more-news-image"
                        />
                        <p className="imageunderpara">
                          {readMoreData?.imageunderpara}
                        </p>
                        <h1 className="categories-topic">
                          {readMoreData?.topic}
                        </h1>
                        <p className="categories-para">
                          <a
                            href={readMoreData?.link1}
                            onClick={handleScrollToForm}
                          >
                            {readMoreData?.link1n}
                          </a>{" "}
                          /{" "}
                          <a href={readMoreData?.link2}>
                            {readMoreData?.link2n}
                          </a>{" "}
                          /{" "}
                          <a href={readMoreData?.link3}>
                            {readMoreData?.link3n}
                          </a>
                        </p>
                        <br />
                        <p className="categories-para">
                          {/* {para} */}
                          <div style={{ marginTop: "-20px" }}>
                            {paras?.length > 0
                              ? paras.map((para, key) => {
                                  return (
                                    <div key={key}>
                                      {para?.subtopic === "" ? (
                                        <></>
                                      ) : (
                                        <p className="categories-topic">
                                          {para?.subtopic}
                                        </p>
                                      )}
                                      <p>{makePara(para?.paragraph)}</p>

                                      <div style={{ marginTop: "-20px" }}>
                                        {para?.images?.length > 0
                                          ? para.images.map(
                                              (imagesurl, key) => {
                                                return (
                                                  <div key={key}>
                                                    <img
                                                      className="news-image"
                                                      src={imagesurl}
                                                      alt="read-more-news-image"
                                                    />
                                                  </div>
                                                );
                                              }
                                            )
                                          : null}
                                      </div>

                                      <div style={{ marginTop: "-40px" }}>
                                        {para?.links?.length > 0
                                          ? para.links.map((link, key) => {
                                              return (
                                                <div>
                                                  <a
                                                    style={{
                                                      color: "blu",
                                                    }}
                                                    href={link}
                                                    key={key}
                                                  >
                                                    {link}
                                                  </a>
                                                  <br />
                                                  <br />
                                                </div>
                                              );
                                            })
                                          : null}
                                      </div>
                                      <br />
                                    </div>
                                  );
                                })
                              : null}
                          </div>
                        </p>
                      </div>
                      <div
                        style={{ backgroundColor: "#F5F5F5", padding: "30px" }}
                      >
                        <div
                          style={{
                            backgroundColor: "#F5F5F5",
                            padding: "30px",
                          }}
                        >
                          {prevId && (
                            <div className="arrow-button-previous">
                              <a
                                href={`/${prevData?.readmore}`}
                                style={{ textAlign: "left" }}
                              >
                                ← Previous Post
                              </a>
                            </div>
                          )}
                          <br />
                          {nextId && (
                            <div className="arrow-button-next">
                              <a
                                href={`/${nextData?.readmore}`}
                                style={{ textAlign: "right" }}
                              >
                                Next Post →
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        style={{ backgroundColor: "#fff", marginTop: "20px" }}
                        id="form-section"
                      >
                        <div style={{ padding: "3em" }}>
                          <Form />
                        </div>
                      </div>
                    </div>
                  </td>
                  <td style={{ width: "auto", verticalAlign: "top" }}>
                    <div style={{ marginLeft: "60px", marginRight: "60px" }}>
                      <RigthtPage />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </center>
      </div>

      <div className="mobile">
        <div
          style={{
            marginTop: "50px",
            marginLeft: "10px",
            marginRight: "10px",
            marginBottom: "20px",
          }}
        >
          <div style={{ backgroundColor: "#fff" }}>
            <div style={{ padding: "2em", paddingBottom: "0em" }}>
              <img
                className="news-image"
                src={readMoreData?.aimage}
                alt="read-more-news-image"
              />
              <h1 className="categories-topic">{readMoreData?.topic}</h1>
              <p className="categories-para">
                <a href={readMoreData?.link1} onClick={handleScrollToForm}>
                  {readMoreData?.link1n}
                </a>{" "}
                / <a href={readMoreData?.link2}>{readMoreData?.link2n}</a> /{" "}
                <a href={readMoreData?.link3}>{readMoreData?.link3n}</a>
              </p>
              <br />
              <p className="categories-para">
                {/* {para} */}
                <div style={{ marginTop: "-20px" }}>
                  {paras?.length > 0
                    ? paras.map((para, key) => {
                        return (
                          <div key={key}>
                            {para?.subtopic === "" ? (
                              <></>
                            ) : (
                              <p className="categories-topic">
                                {para?.subtopic}
                              </p>
                            )}
                            <p>{makePara(para?.paragraph)}</p>

                            <div style={{ marginTop: "-20px" }}>
                              {para?.images?.length > 0
                                ? para.images.map((imagesurl, key) => {
                                    return (
                                      <div key={key}>
                                        <img
                                          className="news-image"
                                          src={imagesurl}
                                          alt="read-more-news-image"
                                        />
                                      </div>
                                    );
                                  })
                                : null}
                            </div>

                            <div style={{ marginTop: "-40px" }}>
                              {para?.links?.length > 0
                                ? para.links.map((link, key) => {
                                    return (
                                      <div>
                                        <a
                                          style={{
                                            color: "blu",
                                          }}
                                          href={link}
                                          key={key}
                                        >
                                          {link}
                                        </a>
                                        <br />
                                        <br />
                                      </div>
                                    );
                                  })
                                : null}
                            </div>
                            <br />
                          </div>
                        );
                      })
                    : null}
                </div>
              </p>
            </div>
            <div style={{ backgroundColor: "#F5F5F5", padding: "30px" }}>
              <div
                style={{
                  backgroundColor: "#F5F5F5",
                  padding: "30px",
                }}
              >
                {prevId && (
                  <div className="arrow-button-previous">
                    <a
                      href={`/${prevData?.readmore}`}
                      style={{ textAlign: "left" }}
                    >
                      ← Previous Post
                    </a>
                  </div>
                )}
                <br />
                {nextId && (
                  <div className="arrow-button-next">
                    <a
                      href={`/${nextData?.readmore}`}
                      style={{ textAlign: "right" }}
                    >
                      Next Post →
                    </a>
                  </div>
                )}
              </div>
            </div>
            <div
              style={{ backgroundColor: "#fff", marginTop: "20px" }}
              id="form-section"
            >
              <div style={{ padding: "2em" }}>
                <Form />
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            marginLeft: "30px",
            marginRight: "30px",
            marginBottom: "80px",
          }}
        >
          <RigthtPage />
        </div>
      </div>

      <Footer />
    </div>
  );
}
